import * as React from "react";
import {
	Accordion,
	AccordionMinimumHeight,
	Boxbutton,
	BoxbuttonColorTheme,
	ButtonColorEnum,
	InputComponent,
	ValidationTypeEnum,
	Modal,
} from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import { IInputValidationResult } from "norlys-ui-components/lib/components/input-component/Input-Component";
import "./CohabitantStep.scss";
import Column from "../../../../Utils/Column/Column";
import { ValidateInput } from "../../../../../Services/ValidationService/ValidationService";
import { useEffect, useState } from "react";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import { MobileWrapper } from "../../../../Utils/MobileMargin/MobileMargin";
import CallMeUpComponent from "../../../../SharedComponents/CallMeUpComponent/CallMeUpComponent";
import { StepEnum } from "../../../../../Models/StepEnum";
import { Box } from "@mui/material";
import { InfoTooltip } from "../../../../Utils/UI/InfoBadge/InfoBadge";

const NameAddressStep = ({
	nextStep,
	previousStep,
	order,
	open,
	toggleOpen,
	setOrder,
	datahubAddress,
	setErrorMode,
}: IStepInterface) => {
	const [allowNext, setAllowNext] = useState(true);
	const [idqTries, setIdqTries] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [cohabError, setCohabError] = useState("");
	const [firstButtonSelected, setFirstButtonSelected] = React.useState(false);
	const [secondButtonSelected, setSecondButtonSelected] = React.useState(false);
	const partnerContentContext = React.useContext(PartnerContentContext);
	const [keyboardPadding, setKeyboardPadding] = useState<number>(0);
	const DOMPurify = require("dompurify")(window);
	const [existingCustomerModalOpen, setExistingCustomerModalOpen] = useState(false);

	useEffect(() => {
		setExistingCustomerModalOpen(order.existingCustomer);
	}, [order.existingCustomer]);

	const handleCprChange = (val: IInputValidationResult): any => {
		if (val.valid) {
			setOrder({
				...order,
				cohabCpr: val.value,
				CohabIDQValid: false,
				CohabDatahubValid: false,
			});
		} else {
			setOrder({
				...order,
				cohabCpr: "",
				CohabIDQValid: false,
				CohabDatahubValid: false,
			});
		}
		setAllowNext(true);
	};

	const handleNameChange = (val: IInputValidationResult): any => {
		if (val.valid) {
			setOrder({
				...order,
				cohabFullName: val.value,
				CohabIDQValid: false,
				CohabDatahubValid: false,
			});
		} else {
			setOrder({
				...order,
				cohabFullName: "",
				CohabIDQValid: false,
				CohabDatahubValid: false,
			});
		}
		setAllowNext(true);
	};

	const formValid = () => {
		return secondButtonSelected || (!!order.cohabCpr && !!order.cohabFullName && allowNext);
	};

	const resolveValidation = (iDQResult: any, datahubResult: any) => {
		order.CohabIDQValid = iDQResult.value.data === "Valid" || iDQResult.status === 500;
		order.CohabDatahubValid =
			datahubResult.value.data?.validationResult === "Valid" || datahubResult.status === 500;
		setIsLoading(false);
		if (iDQResult.value.status === 403) {
			setAllowNext(false);
			setCohabError("IDQError");
		} else if (
			order.CohabIDQValid &&
			order.CohabDatahubValid &&
			!order.InBinding &&
			(!order.InDelivery || (partnerContentContext.resaleEnabled && order.InDelivery))
		) {
			setCohabError("");
			nextStep();
		} else if (!order.CohabIDQValid) {
			setIdqTries(idqTries + 1);
			setAllowNext(false);
			if (idqTries >= 2) {
				setCohabError("IDQError");
			}
		} else if (!order.CohabDatahubValid) {
			setErrorMode("FAQ");
		} else if (order.InBinding || (!partnerContentContext.resaleEnabled && order.InDelivery)) {
			if (partnerContentContext.chargingStandProductIds.length === 0) {
				setErrorMode("ExistingCostumer");
			} else {
				setOrder({ ...order, existingCustomer: true });
			}
		} else {
			setErrorMode("FAQ");
		}
	};

	const ValidateAndNext = async () => {

		if (order.cohabCpr === order.cpr){
			setAllowNext(false);
			setErrorMode("SameCpr");
		}
		else{
			setIsLoading(true);
			ValidateInput(
				order.cohabCpr,
				order.cohabFullName,
				datahubAddress,
				order.deliveryAddressId,
				resolveValidation,
				true,
				partnerContentContext.partnerId
			);
		}		
	};

	const retry = () => {
		setIdqTries(0);
		setCohabError("");
	};

	const handleTouchStart = () => {
		setKeyboardPadding(55);
	};

	return (
		<div>
			<Accordion
				loading={isLoading}
				loadingText={partnerContentContext.stepLoader.text}
				loadingTitle={partnerContentContext.stepLoader.title}
				label={partnerContentContext.cohabitantStep.title}
				open={open}
				click={toggleOpen}
				firstButtonLabel={
					partnerContentContext.cohabitantStep.backButton
						? partnerContentContext.cohabitantStep.backButton
						: "Tilbage"
				}
				firstButtonOutline={true}
				firstButton={previousStep}
				secondButtonLabel={
					partnerContentContext.cohabitantStep.nextButton
						? partnerContentContext.cohabitantStep.nextButton
						: "Næste"
				}
				secondButtonDisabled={!formValid()}
				secondButtonType={formValid() ? ButtonColorEnum.DARK_GREEN : ButtonColorEnum.GREY}
				secondButton={firstButtonSelected ? ValidateAndNext : () => setErrorMode("FAQ")}
				minimumHeight={AccordionMinimumHeight.SMALL}
				firstButtonId="NameCprStepReturn"
				secondButtonId="NameCprStepForward"
				secondButtonShadow={true}>
				<Box marginLeft={"0.7rem"}>
					<p>{partnerContentContext.cohabitantStep.textOne}</p>
					<div className="movingin-wrapper">
						<Boxbutton
							height={60}
							label={partnerContentContext.cohabitantStep.firstBoxButton}
							click={() => {
								setFirstButtonSelected(true);
								setSecondButtonSelected(false);
							}}
							theme={firstButtonSelected ? BoxbuttonColorTheme.GREEN : BoxbuttonColorTheme.WHITE}
							border={true}></Boxbutton>
						<Boxbutton
							height={60}
							label={partnerContentContext.cohabitantStep.secondBoxButton}
							click={() => {
								setFirstButtonSelected(false);
								setSecondButtonSelected(true);
							}}
							theme={secondButtonSelected ? BoxbuttonColorTheme.GREEN : BoxbuttonColorTheme.WHITE}
							border={true}></Boxbutton>
					</div>
				</Box>
				{firstButtonSelected && (
					<div>
						<Box marginLeft={"0.7rem"}>
							<p>
								<b>{partnerContentContext.cohabitantStep.textTwo}</b>
							</p>
							<p>{partnerContentContext.cohabitantStep.textThree}</p>
						</Box>
						<Column fullWidth={true}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<InputComponent
									width={600}
									touchStart={handleTouchStart}
									placeholder={
										partnerContentContext.cohabitantStep.cohabitantNamePlaceholder
											? partnerContentContext.cohabitantStep.cohabitantNamePlaceholder
											: "Samlevers Navn"
									}
									validationType={ValidationTypeEnum.NAME}
									change={handleNameChange}
								/>
								<Box sx={{ padding: 0, margin: "0 0 0 1rem" }}>
									<InfoTooltip
										placement="right"
										message="Skriv samlevers navn, som det står på deres sundhedskort (sygesikringskort)."
									/>
								</Box>
							</Box>
						</Column>
						<Column fullWidthMobile={true}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<InputComponent
									width={600}
									touchStart={handleTouchStart}
									placeholder={
										partnerContentContext.cohabitantStep.cohabitantCPRPlaceholder
											? partnerContentContext.cohabitantStep.cohabitantCPRPlaceholder
											: "Samlevers CPR-nummer"
									}
									validationType={ValidationTypeEnum.CPR_AGE}
									change={handleCprChange}
								/>
								<Box sx={{ padding: 0, margin: "0 0 0 1rem" }}>
									<InfoTooltip
										placement="right"
										message="Norlys Energi bruger din samlevers CPR-nummer til kreditvurdering og oprettelse af dig/jer som kunde. Du kan finde mere information i de gældende betingelser."
									/>
								</Box>
							</Box>
						</Column>
						<MobileWrapper marginBottomViewheight={keyboardPadding}></MobileWrapper>
					</div>
				)}
				{!allowNext && !order.CohabIDQValid && (
					<div className="error-section">
						{partnerContentContext.cohabitantStep.cohabitantIDQUnvalid}
					</div>
				)}
			</Accordion>
			<Modal
				visible={cohabError === "IDQError"}
				onClosing={() => {
					retry();
				}}>
				<div className="outer-container">
					<h1 className="headerText">{partnerContentContext.idqError.title}</h1>
					<div
						className="text"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(partnerContentContext.idqError.text),
						}}></div>
					<CallMeUpComponent
						order={order}
						address={datahubAddress}
						showSecondButton={false}
						idqError={true}
						step={StepEnum.CohabitantStep}
					/>
				</div>
			</Modal>
			<Modal
				visible={existingCustomerModalOpen}
				onClosing={() => setExistingCustomerModalOpen(false)}>
				<div className="outer-container">
					<h1 className="headerText">Eksisterende kunde</h1>
					<p style={{ fontSize: "18px" }}>
						Det ser ud som om du allerede har en el-aftale hos Norlys, vi kan derfor ikke tilbyde
						rabat på køb af el-aftale. Du vil stadig modtage rabat på køb af andre produkter.
					</p>
				</div>
			</Modal>
		</div>
	);
};

export default NameAddressStep;
