//The classes are arranged in the same order as on the website

import { ITransportAndCharges } from "./TransportAndCharges";

class Header {
	name: string = "";
}

class StepLoader {
	title: string = "";
	text: string = "";
}

class HeroBanner {
	title: string = "";
	boldText: string = "";
	text: string = "";
	image: string = "";
	width: string = "";
	imagePlacement: string = "";
}

class AddressStep {
	title: string = "";
	textSectionOne: string = "";
	textSectionTwo: string = "";
	nextButton: string = "";
	backButton: string = "";
	placeHolder: string = "";
}

class ProductStep {
	title: string = "";
	loading: string = "";
	nextButton: string = "";
	backButton: string = "";
	labellingInfoContent: string = "";
	productTitle: string = "";
	productBullets: Array<string> = [""];
	productDetailsLabel: string = "";
	labellingInfoImg: string = "";
	productConditionText: string = "";
}

class CPRStep {
	title: string = "";
	namePlaceholder: string = "";
	nameTooltip: string = "";
	cprPlaceholder: string = "";
	cprTooltip: string = "";
	idqUnvalid: string = "";
	nextButton: string = "";
	backButton: string = "";
}

class MovingInStep {
	title: string = "";
	datePlaceholder: string = "";
	dateTooltip: string = "";
	nextButton: string = "";
	backButton: string = "";
	firstBoxButton: string = "";
	secondBoxButton: string = "";
	cohabLabel: string = "";
}

class CohabitantStep {
	title: string = "";
	textOne: string = "";
	textTwo: string = "";
	textThree: string = "";
	cohabitantNamePlaceholder: string = "";
	cohabitantNameTooltip: string = "";
	cohabitantCPRPlaceholder: string = "";
	cohabitantCPRTooltip: string = "";
	cohabitantIDQUnvalid: string = "";
	nextButton: string = "";
	backButton: string = "";
	firstBoxButton: string = "";
	secondBoxButton: string = "";
}

class ContactInfoStep {
	title: string = "";
	nextButton: string = "";
	backButton: string = "";
	billingAddressLabel: string = "";
	phonePlaceholder: string = "";
	digitalLead: boolean = false;
}

class SummaryStep {
	title: string = "";
	submitOrderButton: string = "";
	terms: string = "";
	termsInfo: string = "";
	nextButton: string = "";
	backButton: string = "";
	subTitle: string = "";
	conditionsText: string = "";
}

class Reciept {
	title: string = "";
	subTitle: string = "";
	infoText: string = "";
	buttonUrl: string = "";
	buttonText: string = "";
	bulletsTitle: string = "";
	bullets: Array<string> | undefined;
	advantagesText: string = "";
	voucherTitle: string = "";
	voucherSubTitle: string = "";
	voucherTextAbove: string = "";
	voucherTextBelow: string = "";
	voucherFooterText: string = "";
	voucherExpirationFooterText: string = "";
}

class InfoError {
	title: string = "";
	text: string = "";
	image: string = "";
	buttonLink: string = "";
	buttonText: string = "";
}

class RootStep {
	title: string = "";
}

class SalesInformationStep {
	title: string = "";
	nextButton: string = "";
	backButton: string = "";
	salesIdPlaceholder: string = "";
	shopIdPlaceholder: string = "";
}

class ChargingStandProductId {
	value: string = "";
}

class ElectricityProductId {
	value: string = "";
}

export class StoreChain {
	name: string = "";
	installationNumber: number | undefined = undefined;
	storeNumber: number = 0;
}

class PartnerContent {
	estimatedYearlyUsage: number = 2000;
	header: Header = new Header();
	heroBanner: HeroBanner = new HeroBanner();
	rootStep: RootStep = new RootStep();
	stepLoader: StepLoader = new StepLoader();
	addressStep: AddressStep = new AddressStep();
	cohabitantStep: CohabitantStep = new CohabitantStep();
	contactInfoStep: ContactInfoStep = new ContactInfoStep();
	movingInStep: MovingInStep = new MovingInStep();
	cprStep: CPRStep = new CPRStep();
	summaryStep: SummaryStep = new SummaryStep();
	reciept: Reciept = new Reciept();
	idqError: InfoError = new InfoError();
	faq: InfoError = new InfoError();
	callMeUp: InfoError = new InfoError();
	callMeUpReciept: InfoError = new InfoError();
	productStep: ProductStep = new ProductStep();
	excistingCostumer: InfoError = new InfoError();
	isValid: boolean = false;
	partner: string = "";
	partnerId: string = "";
	productId: string = "";
	expirationMonths: number = 0;
	voucherValue: number = 0;
	expirationMonthsNewSale: number = 0;
	expirationMonthsResale: number = 0;
	voucherValueNewSale: number = 0;
	voucherValueResale: number = 0;
	resaleEnabled: boolean = false;
	id: string = "";
	outboundSla: string = "";
	salesInformationStep: SalesInformationStep = new SalesInformationStep();
	emobilitySaleEnabled = false;
	financingEnabled = false;
	campaignCodeEnabled = false;
	digitalLeadEnabled = false;
	chargingStandProductIds: ChargingStandProductId[] = [];
	isChargingStandRequired: boolean = false;
	electricityProductIds: ElectricityProductId[] = [];
	isElectricityRequired: boolean = false;
	emobilityInstallationDiscountActive: boolean = false;
	emobilitySubstitutionDiscountActive: boolean = false;
	emobilitySubscriptionDiscountActive: boolean = false;
	partnerInstallationNumber: string = "";
	isStoreChain: boolean = false;
	storeChains: StoreChain[] = [];
	transportAndCharges: ITransportAndCharges | undefined = undefined;
	salesflowRequired: boolean = false;
}

export { PartnerContent, ElectricityProductId, ChargingStandProductId };
