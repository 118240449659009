import React, { useEffect, useState, useContext } from "react";
import "./StepComponent.scss";
import AddressStep from "../Steps/AddressStep/AddressStep";
import NameCprStep from "../Steps/NameCprStep/NameCprStep";
import SummaryStep from "../Steps/SummaryStep/SummaryStep";
import ContactInfoStep from "../Steps/ContactInfoStep/ContactInfoStep";
import { OrderModel } from "../../../../Models/OrderModel";
import Skeleton from "../../../Utils/Skeleton/Skeleton";
import ProductStep from "../../../LandingpageComponents/Wizard/Steps/ProductStep/ProductStep";
import ReceiptStep from "../Steps/ReceiptStep/ReceiptStep";
import AddressModel from "../../../../Models/AddressModel";
import MovingInStep from "../Steps/MovingInStep/MovingInStep";
import CohabitantStep from "../Steps/CohabitantStep/CohabitantStep";
import PartnerContentContext from "../../../../Contexts/PartnerContentContext";
import Overlay from "../Overlay/overlay";
import OverlayModel from "../../../../Models/OverlayModel";
import { OrderResult } from "../../../../Models/CreateOrderResult";
import { Redirect } from "react-router-dom";
import { StepEnum } from "../../../../Models/StepEnum";
import { SalesStep } from "../Steps/SalesStep/SalesStep";
import { UrlHelper } from "../../../../Helpers/UrlHelper";

export interface IStepComponent {
	hideBanner: React.Dispatch<React.SetStateAction<boolean>>;
	order: OrderModel;
	setOrder: React.Dispatch<React.SetStateAction<OrderModel>>;
	address: AddressModel;
	setAddress: React.Dispatch<React.SetStateAction<AddressModel>>;
	step: StepEnum;
	setStep: React.Dispatch<React.SetStateAction<StepEnum>>;
}

export const StepComponent: React.FC<IStepComponent> = (props: IStepComponent) => {
	const { hideBanner, order, setOrder, address, setAddress, step, setStep } = props;
	const overlayObject = new OverlayModel(false, "", false);
	const [orderCompleted, setOrderCompleted] = useState(false);
	const [orderResult, setOrderResult] = useState<Array<OrderResult>>([]);
	const [error, setError] = useState("");
	const [stepReached, setStepReached] = useState(1);

	const partnerContentContext = useContext(PartnerContentContext);

	const [lastScroll, setLastScroll] = useState("");
	const [activeStep, setActiveStep] = useState(StepEnum.NameAddressStep);

	useEffect(() => {

		if (partnerContentContext.salesflowRequired) {
			UrlHelper.ensureSalesflowParameter();
		}

		if (!order) {
			setOrder(new OrderModel("", "", "", "", ""));
			hideBanner(false);
		}
		const url = window.location.href;
		const indexOfQuestionMark = url.indexOf("?");
		if (indexOfQuestionMark !== -1) {
			const urlParameterString = url.substring(indexOfQuestionMark + 1);
			setOrder({
				...order,
				isSalesFlow: UrlHelper.urlContains("salesFlow"),
				urlParameter: urlParameterString,
			});
		}
	}, []);

	const gotoStep = (step: StepEnum, scrollTo?: string) => {
		let stepIndex = step + 1;
		if (stepReached < stepIndex) {
			setStepReached(stepReached < stepIndex ? stepIndex : 1);
		}

		setStep(step);
		setActiveStep(step);

		if (scrollTo) {
			if (lastScroll === "" || lastScroll !== scrollTo) {
				setLastScroll(scrollTo);
				scrollToElement(scrollTo);
			}
		}
	};

	const gotoNextStepBasedOnValidation = () => {
		if (
			(step === StepEnum.CprStep && order.DatahubValid) ||
			(step === StepEnum.MovingInStep && order.startDate) ||
			(step === StepEnum.CohabitantStep && order.CohabDatahubValid)
		) {
			gotoStep(StepEnum.ContactInfoStep, "contactinfo-step");
		} else if (step === StepEnum.CprStep && !order.DatahubValid) {
			gotoStep(StepEnum.MovingInStep, "movingin-step");
		} else if (step === StepEnum.MovingInStep && order.startDate === undefined) {
			gotoStep(StepEnum.CohabitantStep, "cohabitant-step");
		}
	};

	const goBackFromContactInfo = () => {
		if (step === StepEnum.ContactInfoStep && order.DatahubValid) {
			gotoStep(StepEnum.CprStep, "namecpr-step");
		} else if (step === StepEnum.ContactInfoStep && !order.DatahubValid && order.startDate) {
			gotoStep(StepEnum.MovingInStep, "movingin-step");
		} else if (step === StepEnum.ContactInfoStep && !order.startDate) {
			gotoStep(StepEnum.CohabitantStep, "cohabitant-step");
		}
	};

	const scrollToElement = (className: string) => {
		setTimeout(() => {
			(document.querySelector(`.${className}`) as HTMLElement)?.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
			});
		}, 250);
	};

	const completeOrder = () => {
		hideBanner(true);
		setOrderCompleted(true);
	};

	const setErrorMode = (err: string) => {
		setError(err);
	};

	const handleFocusChange = (e: any, step: StepEnum, scrollTo?: string) => {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			gotoStep(step, scrollTo);
		}
	};

	return (
		<div>
			<Overlay
				active={overlayObject.overlayActive}
				message={overlayObject.overlayMessage}
				showLoader={overlayObject.overlayLoader}></Overlay>
			<div className={`wizard-container ${error !== "" || orderCompleted ? "hidden-element" : ""}`}>
				<div className="step-title-section">{partnerContentContext.rootStep.title}</div>

				{
					<div
						className={`step-element address-step ${
							step !== StepEnum.NameAddressStep && "hidden-element-mobile"
						} ${stepReached < 1 && "hidden-desktop"} `}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.NameAddressStep);
						}}>
						<AddressStep
							toggleOpen={() => setActiveStep(StepEnum.NameAddressStep)}
							open={activeStep === StepEnum.NameAddressStep}
							nextStep={() => {
								gotoStep(StepEnum.ProductStep, "product-step");
							}}
							previousStep={() => {
								gotoStep(StepEnum.NameAddressStep);
							}}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				}
				{
					<div
						className={`step-element product-step ${
							step !== StepEnum.ProductStep && "hidden-element-mobile"
						} ${stepReached < 2 && "hidden-desktop"}`}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.ProductStep, "product-step");
						}}>
						<ProductStep
							toggleOpen={() => setActiveStep(StepEnum.ProductStep)}
							open={activeStep === StepEnum.ProductStep}
							nextStep={() => {
								gotoStep(StepEnum.CprStep, "namecpr-step");
							}}
							previousStep={() => {
								gotoStep(StepEnum.NameAddressStep, "address-step");
							}}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				}

				{
					<div
						className={`step-element namecpr-step ${
							step !== StepEnum.CprStep && "hidden-element-mobile"
						} ${stepReached < 3 && "hidden-desktop"}`}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.CprStep, "namecpr-step");
						}}>
						<NameCprStep
							toggleOpen={() => setActiveStep(StepEnum.CprStep)}
							open={activeStep === StepEnum.CprStep}
							nextStep={gotoNextStepBasedOnValidation}
							previousStep={() => {
								gotoStep(StepEnum.ProductStep, "product-step");
							}}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				}

				{
					<div
						className={`step-element movingin-step ${
							step !== StepEnum.MovingInStep && "hidden-element-mobile"
						} ${(stepReached < 4 || (stepReached >= 4 && order.DatahubValid)) && "hidden-desktop"}`}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.MovingInStep, "movingin-step");
						}}>
						<MovingInStep
							toggleOpen={() => setActiveStep(StepEnum.MovingInStep)}
							open={activeStep === StepEnum.MovingInStep}
							nextStep={gotoNextStepBasedOnValidation}
							previousStep={() => {
								gotoStep(StepEnum.CprStep, "namecpr-step");
							}}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				}
				{
					<div
						className={`step-element cohabitant-step ${
							step !== StepEnum.CohabitantStep && "hidden-element-mobile"
						} ${
							(stepReached < 5 ||
								(stepReached >= 5 && order.DatahubValid) ||
								(stepReached >= 5 && order.startDate)) &&
							"hidden-desktop"
						}`}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.CohabitantStep, "cohabitant-step");
						}}>
						<CohabitantStep
							toggleOpen={() => setActiveStep(StepEnum.CohabitantStep)}
							open={activeStep === StepEnum.CohabitantStep}
							nextStep={gotoNextStepBasedOnValidation}
							previousStep={() => {
								gotoStep(StepEnum.MovingInStep, "movingin-step");
							}}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				}
				{
					<div
						className={`step-element contactinfo-step ${
							step !== StepEnum.ContactInfoStep && "hidden-element-mobile"
						} ${stepReached < 6 && "hidden-desktop"}`}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.ContactInfoStep, "contactinfo-step");
						}}>
						<ContactInfoStep
							toggleOpen={() => setActiveStep(StepEnum.ContactInfoStep)}
							open={activeStep === StepEnum.ContactInfoStep}
							nextStep={() => {
								if (order.isSalesFlow) {
									gotoStep(StepEnum.SalesStep, "sales-step");
								} else {
									gotoStep(StepEnum.SummaryStep, "summary-step");
								}
							}}
							previousStep={goBackFromContactInfo}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				}
				{order.isSalesFlow && (
					<div
						className={`step-element sales-step ${
							step !== StepEnum.SalesStep && "hidden-element-mobile"
						} ${stepReached < 7 && "hidden-desktop"}`}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.SalesStep, "sales-step");
						}}>
						<SalesStep
							toggleOpen={() => setActiveStep(StepEnum.SalesStep)}
							open={activeStep === StepEnum.SalesStep}
							nextStep={() => {
								gotoStep(StepEnum.SummaryStep, "summary-step");
							}}
							previousStep={() => {
								gotoStep(StepEnum.ContactInfoStep, "contactinfo-step");
							}}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				)}
				{
					<div
						className={`step-element summary-step ${
							step !== StepEnum.SummaryStep && "hidden-element-mobile"
						} ${stepReached < (order.isSalesFlow ? 8 : 7) && "hidden-desktop"}`}
						onFocus={(e) => {
							handleFocusChange(e, StepEnum.SummaryStep, "summary-step");
						}}>
						<SummaryStep
							setOrderResult={setOrderResult}
							completeOrder={completeOrder}
							toggleOpen={() => setActiveStep(StepEnum.SummaryStep)}
							open={activeStep === StepEnum.SummaryStep}
							nextStep={() => {
								gotoStep(StepEnum.SummaryStep, "summary-step");
							}}
							previousStep={() => {
								if (order.isSalesFlow) {
									gotoStep(StepEnum.SalesStep, "sales-step");
								} else {
									gotoStep(StepEnum.ContactInfoStep, "contactinfo-step");
								}
							}}
							order={order}
							setOrder={setOrder}
							datahubAddress={address}
							setAddress={setAddress}
							setErrorMode={setErrorMode}
						/>
					</div>
				}

				{step !== StepEnum.SummaryStep && <Skeleton />}
			</div>
			{error === "FAQ" && <Redirect push to={`/${partnerContentContext.partnerId}/faq`} />}
			{error === "invalid-meteringpoint" && (
				<Redirect push to={`/${partnerContentContext.partnerId}/invalid-meteringpoint`} />
			)}
			{error === "ExistingCostumer" && (
				<Redirect push to={`/${partnerContentContext.partnerId}/existing-customer`} />
			)}
			{error === "SameCpr" && (
				<Redirect push to={`/${partnerContentContext.partnerId}/same-cpr`} />
			)}
			<div className={`${!orderCompleted ? "hidden-element" : ""}`}>
				{<ReceiptStep orderResult={orderResult} order={order} />}
			</div>
		</div>
	);
};
