export class UrlHelper {
	static urlContains(query: string) {
		query = query.toLowerCase();
		let search = new URLSearchParams(window.location.search?.toLowerCase());
		return search.get(query) !== null;
	}

	static ensureSalesflowParameter() {
		let url = new URL(window.location.href);
		let params = new URLSearchParams(url.search);
	
		if (!params.has('salesFlow')) {
			let newParams = url.search ? url.search + '&salesFlow' : '?salesFlow';
			url.search = newParams;
			window.location.href = url.toString();
		}
	}
}
